/* Bootstrap overrides
-------------------------------------------------- */
// Fonts
@import './fonts';
@import './variables';

$headings-font-weight: 300 !important;
$primary: #4c2ffc;
$light: #fafafa;
$a: #1392ff;

// Theme variables
:root {
  --primary-color: #4c2ffc;
  --primary-background: #4c2ffc1a;
  --primary-shadow: #4c2ffc2e;
  --radius: 10px;
  --link: #4c2ffc8a;
  --danger-color: #e51a3e;
  --danger-background: #e51a3e1a;
  --secondary-shadow: #4c2ffc08;
}

/* Do not move the imports from here. 
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import './../../../node_modules/bootstrap/scss/bootstrap.scss';

// Progress bar
@import './../../../node_modules/react-circular-progressbar/dist/styles.css';

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

@media (max-width: 600px) {
  html {
    height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
  }
}

.h1,
h2,
h3,
h4,
p,
span,
a,
div {
  font-family: 'IBM Plex Sans', $open-sans;
}
a,
.link-style {
  color: #1392ff;
}
.link-second-style {
  color: #9dabbd !important;
  &:hover {
    color: #1392ff !important;
    svg {
      color: #1392ff !important;
    }
  }
}

.form-control {
  overflow: hidden;
  white-space: nowrap;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: toRem(65);
  height: toRem(65);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

.centering {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-n {
  border: none !important;
}
.text {
  color: #9dabbd;
}
.t-shadow {
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}
.btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04) !important;
}
.connect-btns {
  .btn {
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    background-color: #1392ff;
    padding: 15px 20px 15px 16px;
    border-radius: 6px;
    gap: 8px;
    &:hover,
    &:active {
      background-color: #1392ff;
      background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
  }

  .info {
    color: #1392ff;
    margin-left: 48px;
  }
}

.btn {
  transition: none !important;
}
.trust-badge {
  width: 20px;
  height: 20px;
}
/* Navbar
-------------------------------------------------- */

.navbar {
  .dapp-name {
    display: flex;
    align-items: center;
    font-size: toRem(24);
    font-weight: 400;
    color: #9ba5b4;
    margin-left: 1.6428571428571428rem;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: toRem(42);
      background-color: #9ba5b4;
      margin-right: toRem(24);
    }
  }
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

.deploy-safe-ul {
  padding: 0 !important;
  padding-left: 15px !important;
  margin-top: 24px !important;
}

.deploy-safe-ul__dark {
  padding: 0 !important;
  padding-left: 15px !important;
  margin-top: 24px !important;
  color: #fff;
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $black;

      &:hover {
        color: $black;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}
