.unlock-title {
  color: #3d5170;
  .i-icon {
    color: #9ba5b4;
    padding-left: 0.15rem;
  }
}

.unlock-block {
  text-align: center;
  hr {
    margin: 0 0 0.4rem 0;
  }
  span {
    font-weight: 700;
  }
  .unlock {
    width: 100%;
    > a {
      color: #000 !important;
    }

    .btn-unlock {
      padding: 1.2rem 21px;
      margin: 0 !important;
      margin-bottom: 0.7rem !important;
      font-size: 1rem;
      font-weight: 600;
      border: 1px solid #c2c2c2 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      transition: 0.1s;
      background: transparent;

      &:hover {
        background-color: #4c2ffc !important;
        color: #ffff !important;
        & p {
          color: #ffff;
        }
        path:nth-child(1) {
          fill: #ffff;
        }
        path:nth-child(2) {
          fill: #1f43f7 !important;
        }
        path:nth-child(3) {
          fill: #1f43f7 !important;
        }
        .arrow {
          color: #1392ff;
          opacity: 1;
        }
      }
      .method {
        align-items: center;
        gap: 15px;
      }
      .arrow {
        width: 13.01px;
        height: 16px;
        opacity: 0;
      }
    }
    .text {
      color: #3d5170;
    }
  }
}
