.trim {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &.overflow {
    .ellipsis {
      display: block;
    }
  }

  .left {
    flex-shrink: 1;
    font-size: 1px;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .right {
    flex-shrink: 1;
    font-size: 1px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: rtl;
  }

  .left span,
  .right span {
    font-size: 1rem;
    pointer-events: none;
    user-select: none;
  }

  .ellipsis {
    flex-shrink: 0;
    display: none;
    pointer-events: none;
    user-select: none;
  }

  /* IE fix */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .right {
      text-overflow: clip;
    }
  }

  /* SAFARI 10.1+ fix */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .right {
        text-overflow: unset;
      }
    }
  }

  .hidden-text-ref {
    position: absolute;
    display: block;
    color: transparent;
  }
}

.trim-wrapper {
  display: flex;
  max-width: 100%;
  overflow: hidden;
}

a:hover > .trim span {
  color: $link-hover-color !important;
  &.hidden-text-ref {
    color: transparent !important;
  }
}

a > .trim span,
.text-primary > .trim span {
  color: $primary !important;
  &.hidden-text-ref {
    color: transparent !important;
  }
}

.table .trim {
  max-width: 10rem;
}

.table .trim-only-sm .trim {
  max-width: none;

  @include media-breakpoint-down(sm) {
    max-width: 13rem;
  }
}

.trim-fs-sm .trim {
  .left span,
  .right span,
  .ellipsis {
    font-size: 0.875rem;
  }
}

.table .trim-size-xl .trim {
  @include media-breakpoint-up(md) {
    max-width: 13rem;
  }
}

.css-t0ut69-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}
