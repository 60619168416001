.modal-control-container {
  display: block;
  .group-center {
    width: 250px;
    margin: 0 auto;
  }
  label {
    display: block !important;
    color: #9dabbd;
    font-size: 18px;
    line-height: 21px;
  }
  .input-wrapper {
    position: relative;
    input {
      margin-bottom: 5px !important;
    }
    span {
      position: absolute;
      color: #1390ff;
      top: calc(50% - 10px);
      right: 30px;
      margin: 0;
      cursor: pointer;
    }
  }
  input {
    font-size: 18px;
    height: 48px;
    border-radius: 8px;
    line-height: 21px;
    margin-bottom: 32px;
  }
  textarea {
    font-size: 18px;
    border-radius: 8px;
    line-height: 21px;
  }
  span {
    display: block;
    color: #9dabbd;
    margin-bottom: 32px;
  }
  .center {
    margin: 32px auto;
  }
}

.modal-control-container .form-check-label {
  font-size: 16px;
  font-weight: 300;
}

.modal-control-container .form-check-input {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.receive canvas {
  padding: 11px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 4px rgba(150, 160, 181, 0.08);
  border-radius: 10px;
}

.copy-address {
  margin-top: 32px;
  display: flex;
  .address {
    display: flex;
    width: 100%;
    padding: 15px 24px;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
    resize: none;
    line-height: 17px;
    text-align: center;
    outline: none;

    color: #9dabbd;
  }
  .copy-btn {
    display: flex;
    width: 66px;
    height: 66px;
    justify-content: center;
    align-items: center;
    background: #f3f9ff;
    border: 1px solid #ddeeff;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
  svg {
    font-size: 16px;
  }
}

.remove-user {
  .address {
    display: flex;
    width: 100%;
    padding: 15px 24px;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
}

.dapp-core-component__dappModalStyles__dappModal {
    position: fixed;    
    z-index: 1302 !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding-top: 0;
}

div[role='presentation'] {
  z-index: 1301;
}
