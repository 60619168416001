.link-decoration:hover {
  text-decoration: none;
}

.active svg {
  color: #4c2ffc !important;
}
.active p {
  font-weight: 400;
  color: currentColor;
}
.active span {
  font-weight: 400;
  color: #4c2ffc !important;
}
.accordion-details-link .link-hover {
  padding-left: 43px;
}

@media (min-width: 600px) {
  .page-wrapper {
    display: flex;
  }

  .active {
    border-right: 2px solid #4c2ffc;
    display: block;
    color: currentColor;
  }
}
@media (max-width: 600px) {
  .active {
    border-top: 2px solid #4c2ffc;
    display: block;
    color: currentColor;
  }
}

.assets-mobile-submenu {
  display: inline-block;
  width: 50%;
  text-align: center;
  border-bottom: 2px solid #dfdfe8;
}

.active-submenu {
  border-bottom: 2px solid #4c2ffc;
}
