.change-currency-wrapper {
  position: absolute;
  margin-left: -115px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(76, 47, 252, 0.13);
  border-radius: 10px;
  z-index: 2;
}

.search-icon {
  position: absolute;
  margin-top: 15px;
  margin-left: 5px;
}

.currency-input label,
#country-select-demo {
  padding-left: 20px;
}

.currency-input .MuiInputLabel-shrink {
  padding-left: 0px;
}
