.icon-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  &.half {
    width: 3rem;
    height: 3rem;
  }
}
